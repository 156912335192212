import api from "./api";

export interface Donor {
  full_name: string;
  email: string;
  phone_number: string;
  document_value: string;
  accept_terms: boolean;
  product_id: string;
}

export const checkEmail = async (email: string) => {
  const res = await api.post("/email-checker", { email });
  return res.data;
};

export const createDonor = async (donor: Donor) => {
  const res = await api.post("/steps/lead", donor);
  return res.data;
};

export const searchAddress = async (cep: { zipcode: string }) => {
  const res = await api.post("/zipcode/search", cep);
  return res.data;
};
