import { useEffect, useState } from "react";
import styled from "styled-components";
import LogoVmWhite from "../assets/images/logo-com-selo-ongs-2022.svg";
import Kid from "../assets/images/kid.png";
import logoVisao from "../assets/images/logo-visao.svg";
import ArrowIcon from "../assets/icons/arrow-icon.svg";
import Checkout from "./Checkout";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import { useLocation, useMatch } from "react-router-dom";
import fns from "services/sessionStorage";

export type QueryParamsCheckout = {
  product: number;
  value: number;
};

const LpPersonalData = () => {
  const { isDesktop } = useMediaQueriesV3();
  const [step, setStep] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<QueryParamsCheckout>({
    product: 0,
    value: 0,
  });

  const location = useLocation();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.p && params.v) {
      setQueryParams({
        product: parseInt(params.p),
        value: parseInt(params.v),
      });

      fns.set(
        "queryParams",
        { product: parseInt(params.p), value: parseInt(params.v) },
        true
      );
    } else {
      const queryParamsFn = fns.get("queryParams", true);

      if (queryParamsFn) {
        setQueryParams(queryParamsFn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case "/dados-pessoais":
        setStep(1);
        break;
      case "/dados-de-cobranca":
        setStep(2);
        break;
      case "/dados-de-endereco":
        setStep(3);
        break;
      case "/quase-la":
        setStep(4);
        break;
      case "/tudo-certo":
        setStep(5);
        break;
      case "/hum-1":
        setStep(6);
        break;
    }
  }, [location]);

  return (
    <>
      {isDesktop && (
        <>
          <Main>
            <FirstComponentCheckout step={step}>
              <FirstComponentStyled>
                <LogoVisaoWhite src={LogoVmWhite} />
                <KidImg src={Kid} />
                <P>
                  O problema da fome é <b>urgente</b>. Alimente uma
                  criança <b>hoje!</b>
                </P>
              </FirstComponentStyled>
            </FirstComponentCheckout>
            <SecondComponentCheckout>
              <Checkout
                step={step}
                setStep={setStep}
                queryParams={queryParams}
              />
            </SecondComponentCheckout>
          </Main>
          <Footer>
            <FooterContent>
              <ColumnContainer>
                <LogoFooter src={logoVisao} />
                <Description>
                  Sob nenhuma circunstância a Visão Mundial aprova contato de
                  doadores e colaboradores diretamente às crianças e
                  adolescentes sem pré-aprovação e acompanhamento da organização
                  e responsáveis, em conformidade com a Política de Proteção de
                  Crianças e Adultos da WVI e de acordo com os artigos 17 e 18
                  do ECA, Norma de Patrocínio nr 29 e Código Civil. A Visão
                  Mundial tem tolerância zero em relação à violência ou ao abuso
                  contra crianças, adolescentes ou adultos causados por
                  funcionários e/ou parceiros da organização.
                </Description>
                <Copy target="_blank" href="https://visaomundial.org.br/">
                  © 2022 Visão Mundial Brasil
                </Copy>
              </ColumnContainer>
              <ColumnContainer>
                <Title>Mais informações</Title>
                <Information target="_blank" href="https://api.whatsapp.com/send/?phone=5511984840442&text=Quero+saber+mais+Sobre+o+Juntos+pelas+Crian%C3%A7as&type=phone_number&app_absent=0">
                  Fale com a gente pelo Whastapp
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
                <Information
                  href="https://visaomundial.org.br/"
                  target="_blank"
                >
                  Conheça mais a Visão Mundial
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
                <Title style={{ marginTop: 32 }}>Proteção de dados</Title>
                <Information
                  href="https://visaomundial.org.br/politica-de-privacidade"
                  target="_blank"
                >
                  Política de privacidade
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
              </ColumnContainer>
              <ColumnContainer>
                <Title>Mais informações</Title>
                <Information
                  href="https://www.facebook.com/visaomundialbrasil"
                  target="_blank"
                >
                  Facebook
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
                <Information
                  href="https://www.youtube.com/user/visaomundial"
                  target="_blank"
                >
                  Youtube
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
                <Information
                  href="https://twitter.com/visaomundialbr"
                  target="_blank"
                >
                  Twitter
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
                <Information
                  href="https://www.instagram.com/visaomundialbr"
                  target="_blank"
                >
                  Instagram
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
                <Information
                  href="https://www.linkedin.com/company/visaomundialbrasil"
                  target="_blank"
                >
                  Linkedin
                  <ArrowIconFooter src={ArrowIcon} />
                </Information>
              </ColumnContainer>
            </FooterContent>
          </Footer>
        </>
      )}
      {!isDesktop && <Checkout step={step} setStep={setStep} queryParams={queryParams} />}
    </>
  );
};

export default LpPersonalData;

const Main = styled.main`
  background: #ffffff;
  display: flex;
`;

const FirstComponentCheckout = styled.div<{
  step?: number;
}>`
  background: #ff6b00;
  width: 40%;
  height: ${(props) => (props.step === 3 ? "1082px" : "882px")};
  min-height: 100vh;
  position: relative;
`;

const LogoVisaoWhite = styled.img`
  max-width: 315px;
  padding-top: 72px;
  padding-bottom: 32px;
  margin-left: 96px;
`;

const KidImg = styled.img`
  max-width: 560px;
  width: 100%;
  object-fit: contain;
  @media screen and (max-width: 1100px) {
    width: 460px;
  }
  @media screen and (max-width: 960px) {
    width: 360px;
  }
`;

const FirstComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -80px;
  @media screen and (max-width: 960px) {
    right: -20px;
  }
`;

const P = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 101.5%;
  letter-spacing: 0.02em;
  color: #ffffff;
  max-width: 280px;
  margin-left: 96px;
  margin-top: -67px;

  > b {
    font-weight: 800;
  }
`;

const SecondComponentCheckout = styled.div`
  width: 60%;
  max-height: 782px;
`;

const Footer = styled.footer`
  width: 100%;
  background: #333333;
  padding-top: 64px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  z-index: 999;
  position: relative;
`;

const FooterContent = styled.div`
  max-width: 1040px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 1060px) {
    width: 90%;
  }
`;

const LogoFooter = styled.img`
  max-width: 176px;
  object-fit: contain;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.02em;
  color: #999999;
  max-width: 368px;
  margin-top: 16px;
`;

const Copy = styled.a`
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-top: 42px;
  text-decoration: none;
  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ff6b00;
  margin-bottom: 8px;
`;

const Information = styled.a`
  text-decoration: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #b3b3b3;
  margin-top: 8px;
`;

const ArrowIconFooter = styled.img`
  width: 9px;
  margin-left: 8px;
`;
