import Button from "components/Button";
import Input from "components/Input";
import { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import Logo from "assets/images/logo-com-selo-ongs-2022-preto.svg";
import { isValidCep, REQUIRED_LABEL } from "services/validators";
import { Form, Formik } from "formik";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import StepHeader from "components/StepHeader";
import Spinner from "components/Spinner/Spinner";
import { searchAddress } from "services/user";
import { JSEncrypt } from "jsencrypt";
import { CardDataType, ErrorsFields, ErrorsInterface } from ".";
import fns from "services/sessionStorage";
import { createCardPayment, PaymentCardType } from "core";
import { QueryParamsCheckout } from "pages/LpPersonalData";
import { useLocation, useNavigate } from "react-router-dom";
import {Buffer} from 'buffer';

type AddressType = {
  cep: string;
  street: string;
  district: string;
  city: string;
  country: string;
  state: string;
  complement: string;
  number: string;
};

type SetFieldValueProps = {
  (field: string, value: any, shouldValidate?: boolean): void;
};
interface Props {
  step: number;
  setStep: (step: number) => void;
  cardData: CardDataType;
  setCardData: (values: CardDataType) => void;
  queryParamsCheckout: QueryParamsCheckout;
  setErrors: (values: ErrorsInterface) => void;
  errorsResponse: ErrorsInterface;
  solveErrorValidation: (values: ErrorsFields) => void;
}

interface Response {
  street: string;
  district: string;
  state: string;
  city: string;
  country: string;
}

const initialValues: AddressType = {
  cep: "",
  street: "",
  district: "",
  city: "",
  country: "",
  state: "",
  complement: "",
  number: "",
};

interface ErrorResponse {
  response: {
    data: {
      message: string;
      errors: ErrorsInterface;
    };
    status: string;
  };
}

const StepDonorParams = [
  "full_name",
  "email",
  "phone_number",
  "document_value",
];
const StepBillingDataParams = [
  "document",
  "card_number",
  "card_holder_name",
  "card_expiration_date",
  "card_cvv",
];
const StepPaymentAddress = [
  "cep",
  "street",
  "district",
  "city",
  "country",
  "state",
  "complement",
  "number",
];

const PaymentAddress = ({
  step,
  setStep,
  setCardData,
  cardData,
  queryParamsCheckout,
  setErrors,
  errorsResponse,
  solveErrorValidation,
}: Props) => {
  const [isLoadingSpinner, setIsLoadingSpinner] = useState<boolean>(false);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [cepAux, setCepAux] = useState<string>("");
  const [errorsAux, setErrorsAux] = useState<string>("");
  const { isDesktop } = useMediaQueriesV3();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();

  const encrypt = new JSEncrypt();

  const FormSchema = yup.object().shape({
    cep: yup.string().required(REQUIRED_LABEL),
    street: yup.string().required(REQUIRED_LABEL),
    district: yup.string().required(REQUIRED_LABEL),
    city: yup.string().required(REQUIRED_LABEL),
    country: yup.string().required(REQUIRED_LABEL),
    state: yup.string().required(REQUIRED_LABEL),
    complement: yup.string(),
    number: yup.string().required(REQUIRED_LABEL),
  });

  const publicKey = Buffer.from(
    fns.get("res-rsa-public-key") ?? "",
    "base64"
  ).toString("ascii");

  encrypt.setPublicKey(publicKey);

  const handleSubmit = async (values: AddressType) => {
    setLoading(true);
    const newCardData = {
      card_number: fns.get("card_number") || cardData.card_number,
      card_holder_name:
        fns.get("card_holder_name") || cardData.card_holder_name,
      card_expiration_date:
        fns.get("card_expiration_date").replace("/", "") ||
        cardData.card_expiration_date.replace("/", ""),
      card_cvv: fns.get("card_cvv") || cardData.card_cvv,
    };

    const queryString = new URLSearchParams(newCardData).toString();
    const encrypted = encrypt.encrypt(queryString);

    const obj: PaymentCardType = {
      product_id: queryParamsCheckout.product,
      card_data: encrypted.toString(),
      document:
        fns.get("document").replaceAll(".", "").replace("-", "") ||
        cardData.document.replaceAll(".", "").replace("-", ""),
      address: {
        street: values.street,
        number: values.number.toString(),
        district: values.district,
        city: values.city,
        state: values.state,
        country: values.country,
        zipcode: values.cep,
      },
    };

    try {
      const res = await createCardPayment(obj);

      fns.clear();

      setCardData({
        card_cvv: "",
        card_expiration_date: "",
        card_holder_name: "",
        card_number: "",
        document: "",
      });

      navigate(`../quase-la${search}&status=${res.status}`, {
        replace: true,
        state: {
          paymentSuccess: res.status === 204,
        },
      });
    } catch (err: unknown) {
      const e = err as ErrorResponse;
      if (Number(e.response.status) == 422) {
        return navigate(`../quase-la${search}&status=422`, {
          replace: true,
          state: { paymentSuccess: false },
        });
      }
      if (Number(e.response.status) == 422) {
        const errorsReponse = Object.keys(
          e.response.data.errors as ErrorResponse
        );
        const intersectionsStep1 = StepDonorParams.filter((element) =>
          errorsReponse.includes(element)
        );
        const intersectionsStep2 = StepBillingDataParams.filter((element) =>
          errorsReponse.includes(element)
        );
        const intersectionsStep3 = StepPaymentAddress.filter((element) =>
          errorsReponse.includes(element)
        );

        setErrors(e.response.data.errors);

        if (intersectionsStep1.length > 0) {
          navigate(`../dados-pessoais${search}`, {
            replace: true,
            state: { paymentSuccess: false },
          });
        } else if (intersectionsStep2.length > 0) {
          navigate(`../dados-de-cobranca${search}`, {
            replace: true,
            state: { paymentSuccess: false },
          });
        }
      } else {
        fns.clear();

        navigate(`../quase-la${search}`, {
          replace: true,
          state: { paymentSuccess: false },
        });
      }
    }

    setLoading(false);
  };

  const handleCep = async (
    cep: string,
    setFieldValue: SetFieldValueProps,
    cepAux: string
  ) => {
    if (cep.length !== 9) {
      return;
    }
    if (isValidCep(cep) && cep !== cepAux) {
      setIsLoadingSpinner(true);
      setErrorsAux("");
      setInputDisabled(true);
      setTimeout(async () => {
        const obj = {
          zipcode: cep.replace("-", ""),
        };
        try {
          const res: Response = await searchAddress(obj);
          setFieldValue("state", res.state);
          setFieldValue("city", res.city);
          setFieldValue("district", res.district);
          setFieldValue("street", res.street);
          setFieldValue("country", res.country);
          setErrorsAux("");
          setIsLoadingSpinner(false);
          setInputDisabled(false);
        } catch (e) {
          setErrorsAux("CEP não encontrado");
          setIsLoadingSpinner(false);
          setInputDisabled(false);
        }
      }, 3000);
    }
  };

  return (
    <ContentContainer>
      <Content>
        <Header step={step} isDesktop={isDesktop}>
          {isDesktop && <img className="img" src={Logo} alt="" />}
          <StepHeader step={step} setStep={setStep} />
        </Header>
        <Title isDesktop={isDesktop}>
          <span>Endereço de cobrança</span>
        </Title>
        <Formik
          validationSchema={FormSchema}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          isInitialValid={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            isValid,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {isLoadingSpinner ? (
                  <StyledInputCep>
                    <Input
                      name="cep"
                      label="CEP"
                      placeholder="00000-000"
                      onBlur={() => {
                        handleCep(values.cep, setFieldValue, cepAux);
                        setCepAux(values.cep);
                      }}
                      onPaste={(e) => {
                        handleChange("cep")(e.clipboardData.getData("Text"));
                      }}
                      error={touched.cep && errors.cep ? errors.cep : errorsAux}
                      value={values.cep}
                      onChange={(e) => {
                        handleChange("cep")(e.target.value);
                        solveErrorValidation("cep");
                      }}
                      mask={{
                        blocks: [5, 3],
                        delimiters: ["-"],
                        numericOnly: true,
                      }}
                    />
                    {isLoadingSpinner && <Spinner />}
                  </StyledInputCep>
                ) : (
                  <Input
                    name="cep"
                    label="CEP"
                    placeholder="00000-000"
                    onBlur={() => {
                      handleCep(values.cep, setFieldValue, cepAux);
                      setCepAux(values.cep);
                    }}
                    onPaste={(e) => {
                      handleChange("cep")(e.clipboardData.getData("Text"));
                    }}
                    error={touched.cep && errors.cep ? errors.cep : errorsAux}
                    value={values.cep}
                    onChange={(e) => {
                      handleChange("cep")(e.target.value);
                      solveErrorValidation("cep");
                    }}
                    mask={{
                      blocks: [5, 3],
                      delimiters: ["-"],
                      numericOnly: true,
                    }}
                  />
                )}
                <Input
                  disabled={inputDisabled}
                  name="street"
                  label="Rua"
                  error={
                    touched.street && errors.street ? errors.street : undefined
                  }
                  value={values.street}
                  onChange={(e) => {
                    handleChange("street")(e.target.value);
                    solveErrorValidation("street");
                  }}
                />
                <AddressArea>
                  <AddressInput>
                    <Input
                      disabled={inputDisabled}
                      name="number"
                      label="Número"
                      error={
                        touched.number && errors.number
                          ? errors.number
                          : undefined
                      }
                      value={values.number}
                      onChange={(e) => {
                        handleChange("number")(e.target.value);
                        solveErrorValidation("number");
                      }}
                      type="text"
                    />
                  </AddressInput>
                  <AddressInputTwo>
                    <Input
                      disabled={inputDisabled}
                      name="complement"
                      label="Complemento"
                      error={
                        touched.complement && errors.complement
                          ? errors.complement
                          : undefined
                      }
                      value={values.complement}
                      onChange={(e) => {
                        handleChange("complement")(e.target.value);
                        solveErrorValidation("complement");
                      }}
                    />
                  </AddressInputTwo>
                </AddressArea>
                <Input
                  disabled={inputDisabled}
                  name="district"
                  label="Bairro"
                  error={
                    touched.district && errors.district
                      ? errors.district
                      : undefined
                  }
                  value={values.district}
                  onChange={(e) => {
                    handleChange("district")(e.target.value);
                    solveErrorValidation("district");
                  }}
                />
                <Input
                  disabled={inputDisabled}
                  name="city"
                  label="Cidade"
                  error={touched.city && errors.city ? errors.city : undefined}
                  value={values.city}
                  onChange={(e) => {
                    handleChange("city")(e.target.value);
                    solveErrorValidation("city");
                  }}
                />
                <Input
                  disabled={inputDisabled}
                  name="state"
                  label="Estado"
                  error={
                    touched.state && errors.state ? errors.state : undefined
                  }
                  value={values.state}
                  onChange={(e) => {
                    handleChange("state")(e.target.value);
                    solveErrorValidation("state");
                  }}
                />
                <Input
                  disabled={inputDisabled}
                  name="country"
                  label="País"
                  error={
                    touched.country && errors.country
                      ? errors.country
                      : undefined
                  }
                  value={values.country}
                  onChange={(e) => {
                    handleChange("country")(e.target.value);
                    solveErrorValidation("country");
                  }}
                />

                <ButtonsArea>
                  <Button disabled={loading || !isValid} type="submit">
                    {`Pronto, doar R$ ${Number(
                      queryParamsCheckout.value
                    )} mensais`}
                  </Button>
                  <Button
                    outline={true}
                    reverseButton={true}
                    onClick={() => setStep(2)}
                  >
                    Voltar para dados do cartão
                  </Button>
                </ButtonsArea>
              </Form>
            );
          }}
        </Formik>
      </Content>
    </ContentContainer>
  );
};

export default PaymentAddress;

const Header = styled.div<{
  isDesktop: boolean;
  step: number;
}>`
  width: 100%;
  padding-top: ${(props) => (props.isDesktop ? "72px" : "10px")};
  padding-bottom: ${(props) => (props.isDesktop ? "101px" : "10px")};
  height: ${(props) => (props.isDesktop ? "0px" : "25vh")};
  background: #f3f3f3;
  display: ${(props) => (props.step !== 3 ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .img {
    position: absolute;
    top: 51px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  background: white;
  width: 100%;
  // padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`;

const ButtonsArea = styled.div`
  margin-top: 40px;
`;

const Title = styled.div<{
  isDesktop?: boolean;
}>`
  width: 100%;
  display: flex;
  margin-bottom: ${(props) => (props.isDesktop ? "16px" : "24px")};
  align-items: center;

  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 108%;
    letter-spacing: 0.02em;
    margin-top: 24px;
    color: #333333;
  }
`;

const AddressArea = styled.div`
  display: flex;
  justify-content: space-between;
`;
const AddressInput = styled.div`
  width: 49%;
`;

const AddressInputTwo = styled.div`
  width: 49%;
`;

const StyledInputCep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;
