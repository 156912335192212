import styled from "styled-components";
import Logo from "assets/images/Logo-visao-mundial.svg";
import ImgErrorAlert from "assets/icons/alert.svg";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import fns from "../../services/sessionStorage";

interface Props {
  setStep: (step: number) => void;
}

const FeedbackError = ({ setStep }: Props) => {
  const { isDesktop } = useMediaQueriesV3();

  return (
    <Main>
      <LayoutCheckout isDesktop={isDesktop}>
        <Header isDesktop={isDesktop}>
          <ImgLogo src={Logo} alt="" />
        </Header>
        <BodyContainer isDesktop={isDesktop}>
          <Body>
            <Img src={ImgErrorAlert} />
            <Title>Alguma coisa deu errado :(</Title>
            <Paragraph isDesktop={isDesktop}>
              Mas não se preocupe porque entraremos em contato com você assim
              que possível para concluir sua doação
            </Paragraph>
          </Body>
        </BodyContainer>
        <ButtonContainer
          onClick={() => {
            fns.clear();

            window.location.href = `https://${window.location.hostname.replace(
              "checkout.",
              ""
            )}`;
          }}
        >
          Voltar para página inicial
        </ButtonContainer>
        {/* TODO(LucasDev - 23/06/22) :: Caso seja necessário "tentar de novo". */}
        {/* <ButtonContainerTwo onClick={() => setStep()}>Quero tentar de novo</ButtonContainerTwo> */}
      </LayoutCheckout>
    </Main>
  );
};

const Main = styled.div`
  background: #ffffff;
  height: 100vh;
`;

const LayoutCheckout = styled.div<{
  isDesktop: boolean;
}>`
  width: 100%;
  height: 145vh;
  margin: 0 auto;
  background-color: #662b00;
`;

const Header = styled.div<{
  isDesktop: boolean;
}>`
  width: 100%;
  padding-top: ${(props) => (props.isDesktop ? "72px" : "0px")};
  padding-bottom: ${(props) => (props.isDesktop ? "101px" : "0px")};
  height: ${(props) => (props.isDesktop ? "0px" : "20vh")};
  background-color: #662b00;
  display: ${(props) => (props.isDesktop ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImgLogo = styled.img`
  width: 111px;
  height: 24px;
`;

const BodyContainer = styled.div<{
  isDesktop: boolean;
}>`
  padding-top: ${(props) => (props.isDesktop ? "201px" : "80px")};
  margin-left: ${(props) => (props.isDesktop ? "-70px" : "0px")};
  display: flex;
  justify-content: center;
`;

const Body = styled.div``;

const Img = styled.img`
  margin-bottom: 17px;
`;

const Title = styled.h1`
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 116%;
  letter-spacing: 0.02em;
  color: #ffffff;
  max-width: 218px;
  margin-top: 15px;
`;

const Paragraph = styled.div<{
  isDesktop: boolean;
}>`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  letter-spacing: 0.02em;
  color: #ffe1cc;
  max-width: 235px;
  margin-top: 14px;
  margin-bottom: ${(props) => (props.isDesktop ? "56px" : "140px")};
`;

const ButtonContainer = styled.button`
  margin: 0 auto;
  max-width: 328px;
  width: 100%;
  height: 48px;
  background: #ff6b00;
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.2s ease;
  outline: none;
  border: none;
`;

const ButtonContainerTwo = styled.button`
  margin: 0 auto;
  max-width: 328px;
  width: 100%;
  height: 48px;
  background: #662b00;
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: rgba(255, 107, 0, 1);
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.2s ease;
  outline: none;
  border: none;
`;

export default FeedbackError;
