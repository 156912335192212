import Button from "components/Button";
import Input from "components/Input";
import { useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import {
  REQUIRED_LABEL,
  INVALID_FORMAT,
  isValidatedEmail,
} from "services/validators";
import { Form, Formik } from "formik";
import { checkEmail, createDonor } from "services/user";
import Spinner from "components/Spinner/Spinner";
import { toast } from "react-toastify";
import { Donor } from "services/user";
import fns from "../../services/sessionStorage";
import { useNavigate } from "react-router-dom";
import { CpfFormat, PhoneFormat } from "utils/cleaveMasks";
import { cpfIsValid } from "utils/validators";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactLoading from "react-loading";
import { ErrorsFields, ErrorsInterface } from ".";
import { useLocation } from "react-router-dom";

type StepOne = {
  name: string;
  email: string;
  document: string;
  phone: string;
  checkbox: string;
};

type Response = {
  searched: boolean;
  validated: boolean;
};

interface Props {
  errorsResponse: ErrorsInterface;
  solveErrorValidation: (values: ErrorsFields) => void;
}

interface ErrorResponse {
  response: {
    data: {
      message: string;
    };
  };
}

const Donation = ({ errorsResponse, solveErrorValidation }: Props) => {
  const [isCheckboxValue, setIsCheckboxValue] = useState<boolean>(false);
  const [isLoadingSpinner, setIsLoadingSpinner] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);
  const [emailAux, setEmailAux] = useState<string>("");
  const [errorsAux, setErrorsAux] = useState<string>("");
  const [errorsAuxEmail, setErrorsAuxEmail] = useState<string>("");
  const navigate = useNavigate();
  const { search } = useLocation();

  const initialValues: StepOne = {
    name: fns.get("name") || "",
    email: fns.get("email") || "",
    document: fns.get("document") || "",
    phone: fns.get("phone") || "",
    checkbox: "",
  };

  const FormSchema = yup.object().shape({
    name: yup
      .string()
      .required(REQUIRED_LABEL)
      .max(60)
      .min(3, "O campo nome precisa conter pelo menos 3 caracteres."),
    email: yup.string().required(REQUIRED_LABEL).email(INVALID_FORMAT),
    document: yup
      .string()
      .required(REQUIRED_LABEL)
      .min(14, "Preencha seu CPF corretamente.")
      .test("validate-document", function (value?: string) {
        if (!value) return false;
        if (!cpfIsValid(value)) {
          setErrorsAux("CPF inválido.");
          return false;
        } else {
          setErrorsAux("");
          return true;
        }
      }),
    phone: yup
      .string()
      .required(REQUIRED_LABEL)
      .test(
        "validate-phone",
        "errors.format-is-invalid",
        function (value?: string) {
          if (!value) return false;
          return (
            value?.replace(/[^\d]/g, "").length > 9 &&
            isValidPhoneNumber("+55" + (value ?? ""))
          );
        }
      ),
  });

  const handleSubmit = async (values: StepOne) => {
    setIsLoadingButton(true);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const obj: Donor = {
      full_name: values.name,
      email: values.email,
      phone_number: values.phone,
      document_value: values.document,
      accept_terms: isCheckboxValue,
      product_id: params.p,
    };
    try {
      const res: {
        rsa_public_key: string;
        session: { token: string; expired_at: string };
      } = await createDonor(obj);
      fns.set("res-rsa-public-key", res.rsa_public_key);
      fns.set("name", values.name);
      fns.set("email", values.email);
      fns.set("document", values.document);
      fns.set("phone", values.phone);
      fns.set("token", res.session.token);
      navigate(`/dados-de-cobranca${search}`);
    } catch (err: unknown) {
      const e = err as ErrorResponse;
      toast.error(e.response.data.message);
      setIsLoadingButton(false);
    }
  };

  const handleEmail = (email: string, emailAux: string) => {
    if (email && email !== emailAux) {
      setErrorsAuxEmail("");
      setIsLoadingSpinner(true);
      setTimeout(() => {
        if (email && isValidatedEmail(email)) {
          checkEmail(email)
            .then((res: Response) => {
              if (res.validated) {
                setIsLoadingSpinner(false);
              } else {
                setErrorsAuxEmail("Email inválido!");
                setIsLoadingSpinner(false);
              }
            })
            .catch((err) => {
              toast.error("Erro ao comunicar com o servidor!");
              setIsLoadingSpinner(false);
            });
        }
      }, 1000);
    }
  };

  return (
    <Content>
      <Title>
        <TitleNumber>1</TitleNumber>
        <span>Dados Pessoais</span>
      </Title>
      <Formik
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isInitialValid={
          fns.get("name") &&
          fns.get("email") &&
          fns.get("document") &&
          fns.get("phone")
            ? true
            : false
        }
      >
        {({ values, errors, touched, handleChange, handleSubmit, isValid }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Input
                name="document"
                placeholder="000.000.000-00"
                label="CPF"
                error={
                  (errorsResponse.document_value &&
                    errorsResponse.document_value[0]) ||
                  (touched.document && errors.document
                    ? errors.document
                    : errorsAux)
                }
                value={values.document}
                onPaste={(e) => {
                  handleChange("document")(e.clipboardData.getData("Text"));
                }}
                onChange={(e) => {
                  handleChange("document")(e.target.value);
                  solveErrorValidation("document_value");
                }}
                mask={CpfFormat}
              />
              <Input
                name="name"
                label="Nome completo"
                placeholder="Nome"
                error={
                  (errorsResponse.full_name && errorsResponse.full_name[0]) ||
                  (touched.name && errors.name ? errors.name : undefined)
                }
                value={values.name}
                onChange={(e) => {
                  handleChange("name")(e.target.value);
                  solveErrorValidation("full_name");
                }}
                maxLength={80}
              />
              <StyledInputEmail>
                <Input
                  name="email"
                  placeholder="exemplo@exemplo.com"
                  label="Seu melhor email"
                  error={
                    (errorsResponse.email && errorsResponse.email[0]) ||
                    (touched.email && errors.email ? errors.email : undefined)
                  }
                  value={values.email || errorsAuxEmail}
                  onChange={(e) => {
                    handleChange("email")(e.target.value);
                    solveErrorValidation("email");
                  }}
                  maxLength={128}
                  onBlur={() => {
                    handleEmail(values.email, emailAux);
                    setEmailAux(values.email);
                  }}
                />
                {isLoadingSpinner && <Spinner />}
              </StyledInputEmail>
              <Input
                name="phone"
                placeholder="(00) 0 0000-0000"
                label="Celular"
                error={
                  (errorsResponse.phone_number &&
                    errorsResponse.phone_number[0]) ||
                  (touched.phone && errors.phone ? errors.phone : undefined)
                }
                onPaste={(e) => {
                  handleChange("phone")(e.clipboardData.getData("Text"));
                }}
                value={values.phone}
                onChange={(e) => {
                  handleChange("phone")(e.target.value);
                  solveErrorValidation("phone_number");
                }}
                mask={PhoneFormat}
              />
              <CheckBoxContainer>
                <Description>
                  <InputCheckbox
                    checked={isCheckboxValue}
                    onChange={() => setIsCheckboxValue(!isCheckboxValue)}
                    type={"checkbox"}
                    id={"check"}
                  />
                  <SpanStyled>
                    Eu li, estou ciente das condições de tratamento dos meus
                    dados pessoais e dou meu consentimento quando aplicável,
                    conforme exposto na
                    <CheckBoxLink
                      href="https://visaomundial.org.br/politica-de-privacidade"
                      target="_blank"
                    >
                      {" "}
                      Política de Privacidade
                    </CheckBoxLink>
                    .
                  </SpanStyled>
                </Description>
              </CheckBoxContainer>
              <Button type="submit" disabled={!isCheckboxValue || !isValid}>
                {isLoadingButton ? (
                  <ReactLoading type="bubbles" color="#fff" />
                ) : (
                  "Continuar para pagamento"
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
      <Button
        outline={true}
        reverseButton={true}
        onClick={() => {
          window.location.href = `https://${window.location.hostname.replace(
            "checkout.",
            ""
          )}`;
        }}
      >
        Voltar para a página inicial
      </Button>
    </Content>
  );
};

const Content = styled.div`
  background: white;
  width: 100%;
  height: 80vh;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 116%;
    letter-spacing: 0.02em;
    color: #333333;
  }
`;

const TitleNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 8px;
  background: #ff6b00;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

// Checkbox area

const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

// Checkbox input button

const InputCheckbox = styled.input`
  border-radius: 4px;
  margin-right: 12px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    margin-right: 2px;
  }
  &[type="checkbox"]::before {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    background: #ffffff;
    border: 3.5px solid #666666;
    border-radius: 5px;
  }
  &[type="checkbox"]:checked::after {
    content: " ";
    width: 15px;
    height: 15px;
    position: absolute;
    border: 3px solid #fff;
    box-shadow: #ff6b00 0px 0px 0px 4px;
    background: #ff6b00;
    margin-top: 3px;
    margin-left: 2.5px;
    border-radius: 2px;
    @media (max-width: 426px) {
      width: 13px;
      height: 13px;
      box-shadow: #ff6b00 0px 0px 0px 4.5px;
    }
  }
`;

const Description = styled.p`
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #fff;
  text-align: left;
  display: flex;
  justify-content: space-between;
`;
const SpanStyled = styled.span`
  margin-left: 20px;
  color: #666666;
`;

const CheckBoxLink = styled.a`
  color: #ff6b00;
  cursor: pointer;
  text-decoration: none;
`;

const StyledInputEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
`;

export default Donation;
