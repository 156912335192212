import { CleaveOptions } from 'cleave.js/options'

export const CepFormat: CleaveOptions = {
   delimiters: ['-'],
   blocks: [5, 3],
   numericOnly: true,
   delimiterLazyShow: true,
}

export const CnpjFormat: CleaveOptions = {
   delimiters: ['.', '.', '/', '-'],
   blocks: [2, 3, 3, 4, 2],
   numericOnly: true,
   delimiterLazyShow: true,
}

export const CpfFormat: CleaveOptions = {
   delimiters: ['.', '.', '-'],
   blocks: [3, 3, 3, 2],
   numericOnly: true,
   delimiterLazyShow: true,
}

export const CpfCnpjFormat: CleaveOptions = {
   delimiters: ['.', '.', '-'],
   blocks: [3, 3, 3, 3],
   numericOnly: true,
   delimiterLazyShow: true,
}

export const PhoneFormat: CleaveOptions = {
   delimiters: ['(', ')', '\u0020', '-'],
   blocks: [0, 2, 0, 5, 4],
   uppercase: true,
   numericOnly: true,
}
