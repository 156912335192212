import styled from "styled-components";
import Logo from "assets/images/Logo-visao-mundial-white.svg";
import ImgSuccessIcon from "assets/icons/success.svg";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import fns from "../../services/sessionStorage";

interface Props {
  setStep: (step: number) => void;
}

const FeedbackSuccess = ({ setStep }: Props) => {
  const { isDesktop } = useMediaQueriesV3();

  return (
    <Main>
      <LayoutCheckout isDesktop={isDesktop}>
        <Header isDesktop={isDesktop}>
          <ImgLogo src={Logo} alt="" />
        </Header>
        <BodyContainer isDesktop={isDesktop}>
          <Body>
            <Img src={ImgSuccessIcon} />
            <Title>
              Deu tudo certo :) Enviamos um email de confirmação para você
            </Title>
            <Paragraph>
              Se a gente já deu um pulo de alegria aqui, imagine as crianças que
              terão uma vida melhor!
            </Paragraph>
            <ParagraphTwo>E sim, você faz parte disso</ParagraphTwo>
          </Body>
          <FooterLoading>Você</FooterLoading>
        </BodyContainer>
        <ButtonContainer onClick={() => {
          fns.clear();

          window.location.href = `https://${window.location.hostname.replace("checkout.", "")}`
        }}>
          Voltar para página inicial
        </ButtonContainer>
      </LayoutCheckout>
    </Main>
  );
};

const Main = styled.div`
  background: #ffffff;
  height: 100vh;
`;

const LayoutCheckout = styled.div<{
  isDesktop: boolean;
}>`
  width: 100%;
  height: 145vh;
  margin: 0 auto;
  background-color: #ff6b00;
`;

const Header = styled.div<{
  isDesktop: boolean;
}>`
  max-width: 480px;
  width: 100%;
  padding-top: ${(props) => (props.isDesktop ? "72px" : "0px")};
  padding-bottom: ${(props) => (props.isDesktop ? "101px" : "0px")};
  height: ${(props) => (props.isDesktop ? "0px" : "20vh")};
  background-color: #ff6b00;
  display: ${(props) => (props.isDesktop ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImgLogo = styled.img`
  width: 111px;
  height: 24px;
`;

const BodyContainer = styled.div<{
  isDesktop: boolean;
}>`
  padding-top: ${(props) => (props.isDesktop ? "201px" : "80px")};
  padding-left: 32px;
  display: flex;
  justify-content: center;
  margin-left: ${(props) => (props.isDesktop ? "-70px" : "0px")};
`;
const Body = styled.div``;
const Img = styled.img`
  margin-bottom: 17px;
`;

const Title = styled.h1`
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 116%;
  letter-spacing: 0.02em;
  color: #ffffff;
  max-width: 218px;
  margin-top: 15px;
`;

const Paragraph = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  letter-spacing: 0.02em;
  color: #ffe1cc;
  max-width: 235px;
  margin-top: 14px;
`;

const ParagraphTwo = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-top: 14px;
  padding-bottom: 90px;
`;

const FooterLoading = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 116%;
  letter-spacing: 0.02em;
  color: #ff6b00;
`;

const ButtonContainer = styled.button`
  margin: 0 auto;
  max-width: 328px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #ff6b00;
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.2s ease;
  outline: none;
  border: none;
`;

export default FeedbackSuccess;
