import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LpPersonalData from "pages/LpPersonalData";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const Router = (): JSX.Element => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey= {`${process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}`}
      language="pt-BR"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/dados-pessoais" element={<LpPersonalData />} />
          <Route path="/dados-de-cobranca" element={<LpPersonalData />} />
          <Route path="/dados-de-endereco" element={<LpPersonalData />} />
          <Route path="/tudo-certo" element={<LpPersonalData />} />
          <Route path="/quase-la" element={<LpPersonalData />} />
          <Route path="/hum-1" element={<LpPersonalData />} />
          <Route path="*" element={<Navigate to="/dados-pessoais?p=1&v=30" replace />} />
        </Routes>
      </BrowserRouter>
    </GoogleReCaptchaProvider>
  );
};

export default Router;
