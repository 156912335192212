export const cpfRegex = /([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/g;

export const cnpjRegex =
  /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})/g;

export const cnpjCpfRegex =
  /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/g;

export const phoneRegex = /\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}/g;

export const zipCodeRegex = /[0-9]{5}-[0-9]{3}$/g;

export const dateRegex = /^[0-9]{2}[/][0-9]{2}[/][0-9]{4}$/g;

export const hourRegex = /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/g;

export const requiredMessage = "Campo obrigatório";

export const requiredCheckboxMessage = "Necessário confirmar";

export const invalidFormat = "Formato inválido";

export const cpfIsValid = (value: string) => {
  if (!value || !/^(\d{3}\.){2}\d{3}-\d{2}$/.test(value)) return false;

  const withoutSpecialCharacters = value.replaceAll(/\.|-/g, "");
  const length = withoutSpecialCharacters.length;

  if (length !== 11 || /\b(\d)\1+\b/g.test(withoutSpecialCharacters))
    return false;

  // Get the nine first numbers
  let result = withoutSpecialCharacters.slice(0, length - 2);

  for (let i = 9; i <= 10; i++) {
    let sumAux = 0;

    for (let b = i; b >= 1; b--) {
      sumAux += (b + 1) * parseInt(result.charAt(result.length - b));
    }

    const rest = sumAux % 11;
    if (rest >= 2) {
      result = result.concat(`${11 - rest}`);
    } else {
      result = result.concat("0");
    }
  }
  return result === withoutSpecialCharacters;
};
