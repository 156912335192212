import styled from "styled-components";
import { lightTheme } from "styles/theme";
import Logo from "assets/images/logo-com-selo-ongs-2022-preto.svg";
import StepHeader from "components/StepHeader";
import Donor from "./Donor";
import BillingData from "./BillingData";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";

import PaymentAddress from "./PaymentAddress";
import { useCallback, useState } from "react";
import FeedbackSuccess from "pages/FeedbackSuccess/FeedbackSucess";
import { QueryParamsCheckout } from "pages/LpPersonalData";
import FeedbackError from "pages/FeedbackError/FeedbackError";
import FeedbackLoading from "pages/FeedbackLoading/FeedbackLoading";
import usePageTracking from "hooks/useTrackingPage";

interface Props {
  step: number;
  setStep: (step: number) => void;
  queryParams?: QueryParamsCheckout;
}

export type CardDataType = {
  document: string;
  card_number: string;
  card_holder_name: string;
  card_expiration_date: string;
  card_cvv: string;
};

export interface ErrorsInterface {
  card_number?: string[];
  card_holder_name?: string[];
  card_expiration_date?: string[];
  card_cvv?: string[];
  full_name?: string[];
  email?: string[];
  phone_number?: string[];
  document_value?: string[];
  cep?: string[];
  street?: string[];
  district?: string[];
  city?: string[];
  country?: string[];
  state?: string[];
  complement?: string[];
  number?: string[];
}

export type ErrorsFields =
  | "full_name"
  | "email"
  | "phone_number"
  | "document_value"
  | "card_number"
  | "card_holder_name"
  | "card_expiration_date"
  | "card_cvv"
  | "cep"
  | "street"
  | "district"
  | "city"
  | "country"
  | "state"
  | "complement"
  | "number";

const Checkout = ({ step, setStep, queryParams }: Props) => {
  const { isDesktop } = useMediaQueriesV3();
  const [errors, setErrors] = useState<ErrorsInterface>({
    full_name: [],
    card_number: [],
    card_holder_name: [],
    card_expiration_date: [],
    card_cvv: [],
    email: [],
    phone_number: [],
    document_value: [],
    cep: [],
    street: [],
    district: [],
    city: [],
    country: [],
    state: [],
    complement: [],
    number: [],
  });
  const [cardData, setCardData] = useState<CardDataType>({
    document: "",
    card_number: "",
    card_holder_name: "",
    card_expiration_date: "",
    card_cvv: "",
  });

  const SolveErrorValidation = useCallback(
    (fieldName: ErrorsFields) => {
      const newErrors = errors;

      delete newErrors[fieldName];

      setErrors(newErrors);
    },
    [errors]
  );
  //usePageTracking()
  return (
    <Main>
      <LayoutCheckout step={step} isDesktop={isDesktop}>
        {step <= 4 && (
          <Header step={step} isDesktop={isDesktop}>
            {!isDesktop && <img src={Logo} alt="" />}
            <StepHeader step={step} setStep={setStep} />
          </Header>
        )}

        {step === 1 && (
          <Donor
            errorsResponse={errors}
            solveErrorValidation={SolveErrorValidation}
          />
        )}
        {step === 2 && (
          <BillingData
            step={step}
            setStep={setStep}
            setCardData={setCardData}
            cardData={cardData}
            queryParamsCheckout={queryParams ?? { product: 0, value: 0 }}
            errorsResponse={errors}
            solveErrorValidation={SolveErrorValidation}
          />
        )}
        {step === 3 && (
          <PaymentAddress
            step={step}
            setStep={setStep}
            setCardData={setCardData}
            cardData={cardData}
            queryParamsCheckout={queryParams ?? { product: 0, value: 0 }}
            errorsResponse={errors}
            solveErrorValidation={SolveErrorValidation}
            setErrors={setErrors}
          />
        )}
        {step === 4 && <FeedbackLoading step={step} setStep={setStep} />}
        {step === 5 && <FeedbackSuccess setStep={setStep} />}
        {step === 6 && <FeedbackError setStep={setStep} />}
      </LayoutCheckout>
    </Main>
  );
};

const Main = styled.div`
  background: #ffffff;
  height: 100vh;
`;

const LayoutCheckout = styled.div<{
  step: number;
  isDesktop: boolean;
}>`
  width: 100%;
  max-width: ${(props) =>
    props.isDesktop && props.step > 3
      ? "100%"
      : props.isDesktop && props.step <= 3
      ? "464px"
      : "100%"};
  height: 100vh;
  margin: 0 auto;
`;

const Header = styled.div<{
  isDesktop: boolean;
  step: number;
}>`
  width: 100%;
  padding-top: ${(props) => (props.isDesktop ? "72px" : "10px")};
  padding-bottom: ${(props) => (props.isDesktop ? "101px" : "10px")};
  height: ${(props) => (props.isDesktop ? "0px" : "25vh")};
  background-color: ${(props) =>
    props.isDesktop
      ? "none"
      : props.step <= 3
      ? lightTheme.colors.neutral
      : "#CAC4BB"};
  display: ${(props) => (props.step <= 3 ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Checkout;
