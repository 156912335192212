import { lightTheme, darkTheme } from "styles/theme";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "styles/globalStyle";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "routes/Router";

const App = (): JSX.Element => {
  const theme = new Date().getHours() < 18 ? lightTheme : darkTheme;

  return (
    <>
      <ToastContainer />
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router />
      </ThemeProvider>
    </>
  );
};

export default App;
