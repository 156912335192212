export const REQUIRED_LABEL = "Campo obrigatório";

export const PhoneRegex = /\([0-9]{2}\)\s[0-9]{4,5}-[0-9]{4}/g

export const INVALID_FORMAT = 'Formato inválido'

const documentHandler = () => {
   let documentRegex =
      /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/g
   if (process.env.REACT_APP_LANGUAGE === 'mx') {
      documentRegex =
         /^([A-ZÑ\x26]{3,4}[ X]([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))[ X]((-)?([A-Z\d]{3}))?$/
   }
   return documentRegex
}
export const documentRegex = documentHandler()

export const isValidatedEmail = (email: string) => {
   return email
      .toLowerCase()
      .match(
         /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
      );
};

export const isValidCep = (cep: string) => {
   const regex = /^[0-9]{5}-[0-9]{3}$/;
   return regex.test(cep);
}

export const validateNumberCard = (numberCard: string) => {
   const regex = /(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/;
   return regex.test(numberCard);
}
