import { useEffect, useCallback, useState } from "react";
export const isDesktop = (): boolean => window.matchMedia(`(min-width: 850px)`).matches;

const getScreenSizesArray = () => ({
    isDesktop: isDesktop(),
});

export const useMediaQueriesV3 = () => {
    const size = window?.innerWidth || 0;
    const [mode, setMode] = useState({
        ...getScreenSizesArray(),
        isBetween: (start: number, end: number) => start <= size && end >= size,
        size,
    });

    const handleScreenResize = useCallback((e) => {
        const windowSize = e?.currentTarget?.innerWidth || 0;
        const isBetween = (start: number, end: number) => start <= windowSize && end >= windowSize;

        setMode({ ...getScreenSizesArray(), isBetween, size: windowSize });
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleScreenResize);

        return () => {
            window.removeEventListener("resize", handleScreenResize);
        };
    }, [handleScreenResize]);

    return mode;
};
