import styled from "styled-components";
import Cleave from "cleave.js/react";
import { CleaveOptions } from "cleave.js/options";
import ErrorIcon from "assets/icons/error.svg";
import SucessIcon from "assets/icons/sucess.svg";
import { ClipboardEventHandler } from "react";

const Input = ({
  placeholder,
  name,
  error,
  value,
  onChange,
  maxLength,
  type,
  mask,
  sucess,
  label,
  text,
  disabled,
  onBlur,
  onPaste,
  ...props
}: {
  placeholder?: string;
  name: string;
  error: string | undefined;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  type?: string;
  mask?: CleaveOptions;
  sucess?: boolean;
  label?: string;
  text?: string;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onPaste?: ClipboardEventHandler<HTMLInputElement>;
}) => {
  return (
    <>
      <InputContainer
        error={error}
        label={label || ""}
        hasValue={value.length > 0 || typeof value === "number"}
        sucess={sucess}
        disabled={disabled}
      >
        <InputLabeled
          placeholder={placeholder ?? ""}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          type={type}
          onPaste={onPaste}
          options={
            mask ? (mask as CleaveOptions) : { blocks: [9999], delimiter: "" }
          }
          disabled={disabled}
          onBlur={onBlur}
          {...props}
        />
        {error && error.length > 0 && <IconStatus src={ErrorIcon} />}
        {sucess && <IconStatus src={SucessIcon} />}
      </InputContainer>
      {text && <AssistiveText>{text}</AssistiveText>}
      {error && <FormFeedback>{error}</FormFeedback>}
    </>
  );
};

export default Input;

const IconStatus = styled.img`
  margin-right: 5px;
`;

const InputLabeled = styled(Cleave)`
  outline: none;
  border: none;
  background-color: transparent;
  width: 90%;
  height: 80%;
  padding-left: 15px;
  margin-top: 5px;
  font-size: 14px;

  :disabled {
    color: #808080;
  }

  ::-webkit-input-placeholder {
    color: #f3f3f3;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }

  :-ms-input-placeholder {
    color: #f3f3f3;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }

  ::placeholder {
    color: #f3f3f3;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }

  :focus {
    ::-webkit-input-placeholder {
      color: #cccccc;
    }

    :-ms-input-placeholder {
      color: #cccccc;
    }

    ::placeholder {
      color: #cccccc;
    }
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputContainer = styled.div<{
  label: string;
  hasValue: boolean;
  error: string | undefined;
  disabled?: boolean;
  sucess?: boolean;
}>`
  width: 100%;
  height: 64px;
  margin-bottom: 16px;
  background: #f3f3f3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #4d4d4d;
  position: relative;
  border-bottom: 2px solid #f3f3f3;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.hasValue &&
    `
   :after {
    content: attr(label);
    font-size: 12px;
    color: black;
    position: absolute;
    left: 15px;
    bottom: 39px; 
  }

  :focus-within {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid #ff6b00;

    :after{
      color:#ff6b00;
    }
  }
  
  `}

  ${(props) =>
    !props.hasValue &&
    `
    :after {
    content: attr(label);
    font-size: 14px;
    color: black;
    position: absolute;
    left: 15px;
    bottom: 22px;
    transition: bottom ease-in 0.3s;
  }

  :focus-within {
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid #ff6b00;

    :after {
      bottom: 39px;
      font-size: 12px;
      color:#ff6b00;
    }
  }

  ${
    props.disabled &&
    `
   :after {
    color: #808080;
  }
  `
  }

  `}

  ${(props) =>
    props.error &&
    `
  border-bottom: 2px solid #FF0000;
  border-radius: 8px 8px 0px 0px;
  :focus-within {
    border-bottom: 2px solid #FF0000;
  border-radius: 8px 8px 0px 0px;

  :after{
    color:#FF0000;
  }
  `}

${(props) =>
    props.sucess &&
    `
  border-bottom: 2px solid ##62CE2F;
  border-radius: 8px 8px 0px 0px;
  :focus-within {
    border-bottom: 2px solid ##62CE2F;
  border-radius: 8px 8px 0px 0px;
  `}
`;

const FormFeedback = styled.div`
  width: 100%;
  color: red;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.02em;
  margin-top: -8px;
  margin-bottom: 12px;
  margin-left: 16px;
`;

const AssistiveText = styled.div`
  width: 100%;
  color: #808080;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.02em;
  margin-top: -8px;
  margin-bottom: 12px;
  margin-left: 16px;
`;
