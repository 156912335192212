import api from "services/api";

export interface PaymentType {
  product_id: number;
  document: string;
}

export interface PaymentCardType extends PaymentType {
  card_data: string;
  address: {
    street: string;
    number: string;
    district: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
  };
}

export const createDonorStep = async () => {
  const { data } = await api.get("/steps/donor");
  return data;
};

export const createCardPayment = async (data: PaymentCardType) => {
  const response = await api.post("/steps/payment/transaction", data);
  return response;
};

export const createPixPayment = async (data: PaymentType) => {
  const response = await api.post("/steps/payment/transaction/pix", data);
  return response;
};
