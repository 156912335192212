import styled from "styled-components";
import Logo from "assets/images/logo-com-selo-ongs-2022-preto.svg";
import StepHeader from "components/StepHeader";
import ImgLoadingIcon from "assets/icons/loading.svg";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface Props {
  step: number;
  setStep: (step: number) => void;
}

const FeedbackLoading = ({ step, setStep }: Props) => {
  const { isDesktop } = useMediaQueriesV3();
  const navigate = useNavigate();
  const location: any = useLocation();
  const { search } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location && location.state) {
        if (
          location.state.paymentSuccess === true ||
          parseInt(search.slice(-3)) === 204
        ) {
          navigate(`../tudo-certo${search.substring(0,search.length-11)}`);
        } else {
          navigate(`../hum-1${search.substring(0,search.length-11)}`);
        }
      }
    }, 4000);
  }, [navigate, location]);

  return (
    <Main>
      <LayoutCheckout isDesktop={isDesktop}>
        <>
          <Header isDesktop={isDesktop}>
            {!isDesktop && <img src={Logo} alt="" />}
            <StepHeader step={step} setStep={setStep} />
          </Header>

          <BodyContainer>
            <Body>
              <Img src={ImgLoadingIcon} />
              <Title>Quase lá! Estamos acertando os últimos detalhes :)</Title>
              <Paragraph>Seu pagamento está sendo processado</Paragraph>
            </Body>
            <Footer>
              <Animation>
                <Score />
                <Score />
                <Score />
              </Animation>
              Você
            </Footer>
          </BodyContainer>
        </>
      </LayoutCheckout>
    </Main>
  );
};

const Main = styled.div`
  background: #ffffff;
  height: 100vh;
`;

const LayoutCheckout = styled.div<{
  isDesktop: boolean;
}>`
  width: 100%;
  height: 145vh;
  background-color: #cac4bb;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/* const Main = styled.div`
  background: #ffffff;
  height: 100vh;
`;

const LayoutCheckout = styled.div<{
  isDesktop: boolean;
}>`
  width: 100%;
  max-width: ${(props) =>
    (props.isDesktop ? props.isDesktop : false) ? "100vw" : "360px"};
  height: 100vh;
  margin: 0 auto;
  background-color: #cac4bb;
  display: ${(props) => props.isDesktop && "flex"};
  flex-direction: column;
  align-items: center;
`; */

const Header = styled.div<{
  isDesktop: boolean;
}>`
  max-width: 480px;
  width: 100%;
  padding-top: ${(props) => (props.isDesktop ? "72px" : "0px")};
  padding-bottom: ${(props) => (props.isDesktop ? "101px" : "0px")};
  height: ${(props) => (props.isDesktop ? "0px" : "20vh")};
  background-color: #cac4bb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BodyContainer = styled.div`
  max-width: 480px;
  width: 100%;
  padding-top: 130px;
  padding-left: 32px;
`;

const Body = styled.div``;

const Img = styled.img`
  margin-bottom: 17px;
`;

const Title = styled.h1`
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 116%;
  margin-bottom: 16px;
  letter-spacing: 0.02em;
  max-width: 140px;
  color: #333333;
`;

const Paragraph = styled.p`
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  letter-spacing: 0.02em;
  color: #4d4d4d;
  margin-bottom: 140px;
  max-width: 155px;
`;

const Footer = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 116%;
  letter-spacing: 0.02em;
  color: #ff6b00;
  display: flex;
`;

const Animation = styled.div`
  margin-right: 10px;
`;

const Score = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #333333;
  display: inline-block;
  margin: 1.6px;
  animation: scaling 2.5s ease-in-out infinite;

  @keyframes scaling {
    0%,
    100% {
      transform: scale(0.2);
    }
    50% {
      transform: scale(1);
    }
  }

  :nth-child(0) {
    animation-delay: 0.2s;
  }
  :nth-child(1) {
    animation-delay: 0.4s;
  }
  :nth-child(2) {
    animation-delay: 0.6s;
  }
`;

export default FeedbackLoading;
