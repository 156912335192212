import { ReactNode } from "react";
import styled from "styled-components";
import { lightTheme } from "styles/theme";

interface ButtonProps {
  children: ReactNode;
  outline?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  reverseButton?: boolean;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
  children,
  outline,
  disabled,
  type,
  reverseButton,
  onClick,
}) => {
  return (
    <ButtonContainer
      type={type}
      isDisabled={disabled}
      disabled={disabled}
      outline={outline}
      reverseButton={reverseButton}
      onClick={onClick}
    >
      {children}
    </ButtonContainer>
  );
};

export default Button;

const ButtonContainer = styled.button<{
  outline?: boolean;
  isDisabled?: boolean;
  reverseButton?: boolean;
}>`
  margin: 0 auto;
  width: 98%;
  height: 48px;
  background: ${(props) =>
    props.reverseButton
      ? "none"
      : props.isDisabled
      ? "#CCCCCC"
      : lightTheme.colors.primary};
  border-radius: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: ${(props) =>
    props.reverseButton
      ? lightTheme.colors.primary
      : props.isDisabled
      ? "#999999"
      : "#fff"};
  cursor: pointer;
  margin-bottom: 8px;
  transition: 0.2s ease;
  outline: none;
  border: none;
`;
