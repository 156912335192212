import Button from "components/Button";
import Input from "components/Input";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import {
  INVALID_FORMAT,
  REQUIRED_LABEL,
  validateNumberCard,
} from "services/validators";
import { Form, Formik } from "formik";
import Lock from "assets/icons/lock.svg";
import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ReactLoading from "react-loading";
import { CardDataType, ErrorsFields, ErrorsInterface } from "./index";
import { useLocation, useNavigate } from "react-router-dom";
import { CpfFormat } from "utils/cleaveMasks";
import fns from "services/sessionStorage";
import { cpfIsValid } from "utils/validators";
import CardIcon1 from "assets/icons/card-icon-1.svg";
import CardIcon2 from "assets/icons/card-icon-2.svg";
import CardIcon3 from "assets/icons/copy-icon.svg";
import CardIcon4 from "assets/icons/successful-gray.svg";
import { createPixPayment, PaymentType } from "core";
import { QueryParamsCheckout } from "pages/LpPersonalData";

interface Props {
  step: number;
  setStep: (step: number) => void;
  setCardData: (values: CardDataType) => void;
  cardData: CardDataType;
  errorsResponse: ErrorsInterface;
  queryParamsCheckout: QueryParamsCheckout;
  solveErrorValidation: (values: ErrorsFields) => void;
}


const BillingData = ({
  step,
  setStep,
  setCardData,
  cardData,
  errorsResponse,
  queryParamsCheckout,
  solveErrorValidation,
}: Props) => {
  const [errorsCardExpiration, setErrorsCardExpiration] = useState<string>("");
  const [errorsCardNumber, setErrorsCardNumber] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [errorsDocument, setErrorsDocument] = useState<string>("");
  const { isDesktop } = useMediaQueriesV3();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [loading, setLoading] = useState<boolean>(true);
  const [pixLoading, setPixLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [methodPayment, setMethodPayment] = useState<"choose" | "card" | "pix">(
    "choose"
  );
  const [copyed, setCopyed] = useState<boolean>(false);
  const [codePix, setCodePix] = useState<string>("");
  const [imageQrCodePix, setImageQrCodePix] = useState<string>("");

  const initialValues: CardDataType = {
    document: fns.get("document") || "",
    card_number: cardData.card_number || fns.get("card_number") || "",
    card_holder_name:
      cardData.card_holder_name || fns.get("card_holder_name") || "",
    card_expiration_date:
      cardData.card_expiration_date || fns.get("card_expiration_date") || "",
    card_cvv: cardData.card_cvv || "",
  };

  const getQrCode = async () => {
    setLoading(true);
    setPixLoading(true);
    const obj: PaymentType = {
      product_id: queryParamsCheckout.product,
      document:
        fns.get("document").replaceAll(".", "").replace("-", "") ||
        cardData.document.replaceAll(".", "").replace("-", ""),
    };

    try {
      const res = await createPixPayment(obj);
      setCodePix(res.data.pix_qr_code);
      setImageQrCodePix(
        `https://gerarqrcodepix.com.br/api/v1?brcode=${res.data.pix_qr_code}&tamanho=256`
      );
      setMethodPayment("pix");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      navigate(
        `../dados-pessoais${search.split("&")[0] + "&" + search.split("&")[1]}`
      );
      setStep(1);
      setPixLoading(false);
    }
  };

  useEffect(() => {
    const methodPaymentSelected =
      search.includes("methodpayment=pix") ||
      search.includes("methodpayment=card");

    const isCard = search.includes("card");

    if (!methodPaymentSelected) return;

    if (isCard) {
      setMethodPayment("card");
    } else {
      getQrCode();
    }
  }, [
      search, 
      cardData, 
      setCodePix, 
      setImageQrCodePix, 
      setMethodPayment, 
      setLoading, 
      setStep, 
      setPixLoading, 
      navigate,
      queryParamsCheckout
    ]);

  const handleSubmit = (values: CardDataType) => {
    setLoading(true);
    setTimeout(() => {
      setCardData(values);

      fns.set("card_expiration_date", values.card_expiration_date);
      fns.set("card_holder_name", values.card_holder_name);
      fns.set("card_number", values.card_number);
      fns.set("card_cvv", values.card_cvv);

      navigate(
        `../dados-de-endereco${
          search.split("&")[0] + "&" + search.split("&")[1]
        }`
      );

      setLoading(false);
    }, 2000);
  };

  const FormSchema = yup.object().shape({
    document: yup
      .string()
      .required(REQUIRED_LABEL)
      .min(14, "Preencha seu CPF corretamente.")
      .test("validate-document", function (value?: string) {
        if (!value) return false;
        if (!cpfIsValid(value)) {
          setErrorsDocument("CPF inválido.");
          return false;
        } else {
          setErrorsDocument("");
          return true;
        }
      }),
    card_number: yup
      .string()
      .required(REQUIRED_LABEL)
      .min(16, "Número de cartão incorreto.")
      .test("validate-card", INVALID_FORMAT, function (value?: string) {
        if (!value) return false;
        const resp = validateNumberCard(
          value.replace(" ", "").replace(" ", "").replace(" ", "")
        );

        resp
          ? setErrorsCardNumber("")
          : setErrorsCardNumber("Número de cartão inválido");

        return resp;
      }),
    card_holder_name: yup.string().required(REQUIRED_LABEL),
    card_expiration_date: yup
      .string()
      .required(REQUIRED_LABEL)
      .min(7, "Por favor, informe o mês e o ano completo")
      .test("validate", "error-date", function (value?: string) {
        const monthInput = Number(value?.substring(0, 2));
        const yearInput = Number(value?.substring(3, 7));
        const date = new Date();
        const data = date.toLocaleDateString("pt-BR");
        const mounth = Number(data.substring(3, 5));
        const year = Number(data.substring(6, 10));

        if (monthInput < 1 || monthInput > 12 || yearInput < year) {
          setErrorsCardExpiration("Data de expiração inválida");
          return false;
        } else if (yearInput === year && monthInput > 12) {
          setErrorsCardExpiration("Data de expiração inválida");
          return false;
        } else if (yearInput === year && monthInput < mounth) {
          setErrorsCardExpiration("Data de expirada");
          return false;
        } else {
          setErrorsCardExpiration("");
          return true;
        }
      }),
    card_cvv: yup
      .string()
      .required(REQUIRED_LABEL)
      .min(3, "O campo CVV deve conter pelo menos 3 caracteres.")
      .max(4),
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    if (token && token.length > 0) {
      return;
    }

    const tokenResponse = await executeRecaptcha();
    setToken(tokenResponse);
    setLoading(false);
  }, [executeRecaptcha, token]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const copyTextToClipboard = (text: string) => {
    /*    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    } */
    navigator.clipboard.writeText(text).then(
      function () {
        setCopyed(true);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  return (
    <Content>
      <Title isDesktop={isDesktop}>
        <TitleNumber>2</TitleNumber>
        <span>Dados de pagamento</span>
      </Title>
      {methodPayment === "choose" && (
        <>
          <Subtitle>Você pode ajudar de duas formas</Subtitle>
          <SubtitleDescription>
            <b>Doação mensal</b> para transformar a realidade dessas crianças,
            garantindo seu desenvolvimento saudável e um futuro.
            <br /> <b>Doação única </b>
            para um impacto imediato.
          </SubtitleDescription>

          <CardContainer>
            <Card
              onClick={() => {
                setMethodPayment("card");
                navigate(
                  `../dados-de-cobranca${
                    search.split("&")[0] + "&" + search.split("&")[1]
                  }&methodpayment=card`
                );
              }}
            >
              <img src={CardIcon1} alt="card icon" />
              <h1>Quero doar mensalmente por cartão de crédito</h1>
              <hr />
            </Card>

            {pixLoading ? (
              <CardLoading>
                <ReactLoading type="bubbles" color="#333333" />
              </CardLoading>
            ) : (
              <Card
                onClick={() => {
                  navigate(
                    `../dados-de-cobranca${
                      search.split("&")[0] + "&" + search.split("&")[1]
                    }&methodpayment=pix`
                  );
                }}
              >
                <img src={CardIcon2} alt="card icon" />
                <h1>Quero doar somente uma vez por pix</h1>
                <hr />
              </Card>
            )}
          </CardContainer>
          <Button
            outline={true}
            reverseButton={true}
            onClick={() => setStep(1)}
          >
            Voltar para dados pessoais
          </Button>
        </>
      )}

      {methodPayment === "pix" && (
        <>
          <Subtitle>Faça sua doação por pix</Subtitle>
          <SubtitleDescription>
            Utilize o aplicativo do seu banco para escanear o QR Code Pix, ou
            copie e cole o Código Pix para concluir o seu pagamento.
          </SubtitleDescription>

          <CardContainer>
            <Card copyed={copyed} onClick={() => copyTextToClipboard(codePix)}>
              {copyed ? (
                <>
                  <img src={CardIcon4} alt="card icon" />
                  <h1>Código pix copiado! Cole no aplicativo do seu banco</h1>
                  <hr />
                </>
              ) : (
                <>
                  <img src={CardIcon3} alt="card icon" />
                  <h1>Copiar codigo pix</h1>
                  <hr />
                </>
              )}
            </Card>
            <QrCodeCard>
              <img src={imageQrCodePix} alt="" />
            </QrCodeCard>
          </CardContainer>

          <Button
            onClick={() =>
              (window.location.href =
                "https://juntospelascriancas.visaomundial.org.br/")
            }
          >
            Pronto, ir para a página inicial
          </Button>
          <Button
            outline={true}
            reverseButton={true}
            onClick={() => {
              navigate(
                `../dados-pessoais${
                  search.split("&")[0] + "&" + search.split("&")[1]
                }`
              );
              setStep(1);
            }}
          >
            Voltar para dados pessoais
          </Button>
        </>
      )}

      {methodPayment === "card" && (
        <>
          <InformationSecurity isDesktop={isDesktop}>
            <img src={Lock} alt="lock" />
            <span>
              Não se preocupe, suas informações estarão protegidas em nosso
              sistema.
            </span>
          </InformationSecurity>

          <Formik
            validationSchema={FormSchema}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            isInitialValid={
              cardData.document &&
              cardData.card_number &&
              cardData.card_holder_name &&
              cardData.card_cvv &&
              cardData.card_expiration_date
                ? true
                : false
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isValid,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Input
                    name="document"
                    label="CPF do titular do cartão"
                    placeholder="000.000.000-00"
                    error={
                      (errorsResponse.document_value &&
                        errorsResponse.document_value[0]) ||
                      (touched.document && errors.document
                        ? errors.document
                        : errorsDocument)
                    }
                    value={values.document}
                    onPaste={(e) => {
                      handleChange("document")(e.clipboardData.getData("Text"));
                    }}
                    onChange={(e) => {
                      handleChange("document")(e.target.value);
                      solveErrorValidation("document_value");
                    }}
                    mask={CpfFormat}
                  />
                  <Input
                    name="card_number"
                    label="Número do cartão de crédito"
                    placeholder="0000 0000 0000 0000"
                    error={
                      (errorsResponse.card_number &&
                        errorsResponse.card_number[0]) ||
                      (touched.card_number && errors.card_number
                        ? errors.card_number
                        : errorsCardNumber)
                    }
                    value={values.card_number}
                    onPaste={(e) => {
                      handleChange("card_number")(
                        e.clipboardData.getData("Text")
                      );
                    }}
                    onChange={(e) => {
                      handleChange("card_number")(e.target.value);
                      solveErrorValidation("card_number");
                    }}
                    mask={{ creditCard: true }}
                  />
                  <Input
                    name="card_holder_name"
                    label="Nome impresso no cartão"
                    placeholder="Insira o nome impresso no cartão"
                    error={
                      (errorsResponse.card_holder_name &&
                        errorsResponse.card_holder_name[0]) ||
                      (touched.card_holder_name && errors.card_holder_name
                        ? errors.card_holder_name
                        : undefined)
                    }
                    value={values.card_holder_name}
                    onChange={(e) => {
                      handleChange("card_holder_name")(e.target.value);
                      solveErrorValidation("card_holder_name");
                    }}
                  />
                  <Input
                    name="card_expiration_date"
                    placeholder="00/0000"
                    label="Validade"
                    error={
                      (errorsResponse.card_expiration_date &&
                        errorsResponse.card_expiration_date[0]) ||
                      (touched.card_expiration_date &&
                      errors.card_expiration_date
                        ? errors.card_expiration_date
                        : errorsCardExpiration)
                    }
                    value={values.card_expiration_date}
                    onChange={(e) => {
                      handleChange("card_expiration_date")(e.target.value);
                      solveErrorValidation("card_expiration_date");
                    }}
                    maxLength={7}
                    mask={{
                      blocks: [2, 4],
                      delimiters: ["/"],
                      numericOnly: true,
                    }}
                  />
                  <Input
                    name="card_cvv"
                    label="Código de segurança"
                    placeholder="CVV"
                    error={
                      (errorsResponse.card_cvv && errorsResponse.card_cvv[0]) ||
                      (touched.card_cvv && errors.card_cvv
                        ? errors.card_cvv
                        : undefined)
                    }
                    value={values.card_cvv}
                    onPaste={(e) => {
                      handleChange("card_cvv")(e.clipboardData.getData("Text"));
                    }}
                    onChange={(e) => {
                      handleChange("card_cvv")(e.target.value);
                      solveErrorValidation("card_cvv");
                    }}
                    type="number"
                    maxLength={4}
                    mask={{
                      blocks: [4],
                      numericOnly: true,
                    }}
                  />
                  <div
                    style={{ transform: "scale(1.1)", transformOrigin: "0 0" }}
                  >
                    <GoogleReCaptcha onVerify={handleReCaptchaVerify} />
                  </div>
                  <Button type="submit" disabled={!isValid}>
                    {loading ? (
                      <ReactLoading type="bubbles" color="#fff" />
                    ) : (
                      "Continuar para Endereço de Cobrança"
                    )}
                  </Button>
                  <Button
                    outline={true}
                    reverseButton={true}
                    onClick={() => {
                      navigate(
                        `../dados-pessoais${
                          search.split("&")[0] + "&" + search.split("&")[1]
                        }`
                      );
                      setStep(1);
                    }}
                  >
                    Voltar para dados pessoais
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </Content>
  );
};

const Content = styled.div`
  background: white;
  width: 100%;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`;

const Title = styled.div<{
  isDesktop?: boolean;
}>`
  width: 100%;
  display: flex;
  margin-bottom: ${(props) => (props.isDesktop ? "16px" : "24px")};
  align-items: center;

  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 116%;
    letter-spacing: 0.02em;
    color: #333333;
  }
`;

const Subtitle = styled.span`
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 108%;
  letter-spacing: 0.02em;
  color: #333333;
`;

const SubtitleDescription = styled.p`
  line-height: 128%;
  letter-spacing: 0.02em;
  margin-top: 8px;
  color: #333333;
  //styleName: Paragraph;
  font-family: "Lato";
  font-size: 15.5px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;

  b {
    font-weight: 700;
  }
`;

const Card = styled.div<{ copyed?: boolean }>`
  height: 176px;
  width: 176px;
  background: #f1f1f1;
  border-radius: 8px;
  position: relative;
  padding: 18px;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;
    color: #333333;
    position: absolute;
    width: 80%;
    bottom: 32px;
    color: ${(props) => (props.copyed ? "#999999" : "")};
  }

  hr {
    height: 0px;
    border-radius: 8px;
    border: 2px solid ${(props) => (props.copyed ? "#999999" : "#ff6b00")};
    position: absolute;
    width: 80%;
    bottom: 8px;
  }
`;

const CardLoading = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;
`;

const QrCodeCard = styled.div`
  height: 176px;
  width: 176px;
  background: #f1f1f1;
  border-radius: 8px;
  position: relative;
  padding: 10px;
  background: #333333;

  img {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: center;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 20px;
`;

const TitleNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 8px;
  background: #ff6b00;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
`;

const CardDataTitle = styled.h1`
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 108%;
  letter-spacing: 0.02em;
  color: #333333;
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  margin-top: 48px;
`;

export default BillingData;

const InformationSecurity = styled.div<{
  isDesktop?: boolean;
}>`
  display: flex;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  border: 1px dashed #666666;
  border-radius: 8px;
  margin-bottom: 16px;
  span {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 129.5%;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #1a1a1a;
  }
  img {
    width: 18px;
    margin-right: 5px;
  }
`;
