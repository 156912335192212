import { useMediaQueriesV3 } from "hooks/useMediaQueriesV3";
import styled from "styled-components";
import { lightTheme } from "styles/theme";
import Shape from "../../assets/icons/shape.svg";

interface StepHeaderProps {
  step: number;
  setStep: (step: number) => void;
}

const StepHeader = ({ step, setStep }: StepHeaderProps) => {
  const { isDesktop } = useMediaQueriesV3();
  return (
    <Container>
      {isDesktop && (
        <ColumnContainer>
          <NumberStepOne active={true} step={step}>
            {step === 1 ? 1 : <Img src={Shape} />}
          </NumberStepOne>
          <p>Dados pessoais</p>
        </ColumnContainer>
      )}
      {!isDesktop && (
        <ColumnContainerMobile>
          <NumberStepOne active={true} step={step}>
            {step === 1 ? 1 : <Img src={Shape} />}
          </NumberStepOne>
          <p>Dados pessoais</p>
        </ColumnContainerMobile>
      )}
      <HrStepOne step={step} />
      {isDesktop && (
        <ColumnContainerStepTwo step={step}>
          <NumberStepTwo active={step === 2 || step === 3} step={step}>
            {step !== 4 ? 2 : <Img src={Shape} />}
          </NumberStepTwo>
          <p>Dados de pagamento</p>
        </ColumnContainerStepTwo>
      )}
      {!isDesktop && (
        <ColumnContainerStepTwo step={step}>
          <NumberStepTwo active={step === 2 || step === 3} step={step}>
            {step !== 4 ? 2 : <Img src={Shape} />}
          </NumberStepTwo>
          <p>Dados de pagamento</p>
        </ColumnContainerStepTwo>
      )}

      <HrStepTwo step={step} />
      {isDesktop && (
        <ColumnContainerStepTree step={step}>
          <NumberStepTree active={step === 4}>3</NumberStepTree>
          <p>Doação concluída</p>
        </ColumnContainerStepTree>
      )}
      {!isDesktop && (
        <ColumnContainerStepTree step={step}>
          <NumberStepTree active={step === 4}>3</NumberStepTree>
          <p>Doação concluída</p>
        </ColumnContainerStepTree>
      )}
    </Container>
  );
};

export default StepHeader;

const Container = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  margin-top: 25px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
`;

const NumberStepOne = styled.div<{ active?: boolean; step?: number }>`
  width: 33px;
  height: 33px;
  border-radius: 100px;
  border: 2px solid ${lightTheme.colors.darkNeutral};
  background-color: ${(props) =>
    props.step !== 1 ? lightTheme.colors.primary : "#fff"};
  color: ${lightTheme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.active &&
    `
       border: 2px solid ${lightTheme.colors.primary};
       color: ${lightTheme.colors.primary};
  `}
  transition: all 0.9s ease;
`;

const NumberStepTwo = styled.div<{ active?: boolean; step?: number }>`
  width: 33px;
  height: 33px;
  border-radius: 100px;
  border: 2px solid ${lightTheme.colors.darkNeutral};
  background: ${(props) =>
    props.step !== 4 ? "#fff" : lightTheme.colors.primary};
  color: ${lightTheme.colors.darkNeutral};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.active &&
    `
       border: 2px solid ${lightTheme.colors.primary};
       color: ${lightTheme.colors.primary};
  `}
  transition: all 0.9s ease;
`;

const NumberStepTree = styled.div<{ active?: boolean; step?: number }>`
  width: 33px;
  height: 33px;
  border-radius: 100px;
  border: 2px solid ${lightTheme.colors.darkNeutral};
  background: ${(props) =>
    props.step === 4 ? lightTheme.colors.primary : "#fff"};
  color: ${lightTheme.colors.darkNeutral};
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.active &&
    `
       border: 2px solid ${lightTheme.colors.primary};
       color: ${lightTheme.colors.primary};
  `}
  transition: all 0.9s ease;
`;

const HrStepOne = styled.hr<{ step?: number }>`
  height: 3px;
  border: none;
  width: 100%;
  background-color: ${(props) =>
    props.step !== 1
      ? lightTheme.colors.primary
      : lightTheme.colors.darkNeutral};
  transition: all 0.9s ease;
  margin-left: 2px;
`;

const HrStepTwo = styled.hr<{ step?: number }>`
  height: 3px;
  border: none;
  width: 100%;
  background-color: ${(props) =>
    props.step === 4
      ? lightTheme.colors.primary
      : lightTheme.colors.darkNeutral};
  transition: all 0.9s ease;
  margin-left: 2px;
`;

const Img = styled.img`
  width: 13px;
  object-fit: contain;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-right: -9px;
  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => lightTheme.colors.primary};
    margin-top: 8px;
  }
`;

const ColumnContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-right: -9px;
  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #ff6b00;
    margin-top: 8px;
  }
`;

const ColumnContainerStepTwo = styled.div<{
  step?: number;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-right: -20px;
  margin-left: -19px;
  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) =>
      props.step === 1 ? "#808080" : lightTheme.colors.primary};
    margin-top: 8px;
    width: 72px;
  }
`;

const ColumnContainerStepTree = styled.div<{ step?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-right: -20px;
  margin-left: -21px;
  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) =>
      props.step === 4 ? lightTheme.colors.primary : "#808080"};
    margin-top: 8px;
    width: 72px;
  }
`;
