export const darkTheme = {
  text: {
    title: {
      fontFamily: "Lato",
      fontColor: "#23262F",
    },
    description: {
      fontFamily: "Lato",
      fontColor: "#909092",
    },
  },
  colors: {},
};

export const lightTheme = {
  text: {
    title: {
      fontFamily: "Lato",
      fontColor: "#23262F",
    },
    description: {
      fontFamily: "Lato",
      fontColor: "#909092",
    },
  },
  colors: {
    primary: "#FF6B00",
    neutral: "#F3F3F3",
    darkNeutral: "#CAC4BB",
  },
};
